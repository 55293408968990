import { graphql } from "gatsby"
import * as React from "react"
import { ShapeDivider } from "./shape-divider"
import { CardsCarousel } from "./slices/cards-carousel"
import { SliceParameters } from "./slices/default"
import { FeaturedProducts } from "./slices/featured_products"
import { FullWidthImage } from "./slices/full-width-image"
import { ImageGallery } from "./slices/image-gallery"
import { ImageHighlight } from "./slices/image-highlight"
import { HeroSlice } from "./slices/hero-slice"
import { Quote } from "./slices/quote"
import { TestimonialCarousel } from "./slices/testimonial-carousel"
import { Text } from "./slices/text"
import { dividerBottom } from "./slices/slice.module.less"
import { VideoHighlight } from "./slices/video-highlight"
import { HtmlSlice } from "./slices/html-slice"

export const query = graphql`
  fragment SectionTheme on PrismicSectionTheme {
    data {
      text_color
      background_color
      wave_count
      min_height
      background_image {
        alt
        copyright
        url
        fixed(width: 2000, height: 500, imgixParams: { fit: "crop" }) {
          src
          srcSet
        }
      }
      secondary_image {
        alt
        gatsbyImageData(width: 1199)
      }
    }
  }
`

const SliceZone = ({ sliceZone, padded = false }) => {
  const sliceComponents: Record<string, React.FC<SliceParameters>> = {
    full_width_image: FullWidthImage,
    image_gallery: ImageGallery,
    image_highlight: ImageHighlight,
    video_highlights: VideoHighlight,
    videos: VideoHighlight,
    hero: HeroSlice,
    html_code: HtmlSlice,
    quote: Quote,
    text: Text,
    featured_products: FeaturedProducts,
    cards_carousel: CardsCarousel,
    testimonial_carousel_: TestimonialCarousel, // Needs the underscore since a duplicate name of existing
  }
  //   return (
  //     !!sliceZone &&
  //     sliceZone.map((slice, index) => <p key={index}>{slice.slice_type}</p>)
  //   )
  // Amount to offset each wave
  const sliceOffsetDiff = 200
  const sliceZoneContent = sliceZone.map((slice, index, slices) => {
    // const headerColor = "var(--default-section-background-color)"
    let nextColor: string = null
    let nextHasImage: boolean = false
    if (index < slices.length) {
      nextColor =
        slices[index + 1]?.primary?.theme?.document?.data?.background_color
      nextHasImage =
        !!slices[index + 1]?.primary?.theme?.document?.data?.background_image
          .url
    }
    const footerColor = nextColor || "var(--default-section-background-color)"
    const SliceComponent = sliceComponents[slice.slice_type]
    const showFooter = !nextHasImage || (nextColor && nextHasImage) // && !!nextColor
    // Show header component if a background image has a url anf previous slice has a color
    const showHeader =
      index > 0 &&
      !!slice.primary?.theme?.document?.data?.background_image.url &&
      !!slices[index - 1].primary?.theme?.document?.data?.background_color

    // const showHeader = !!slice.primary?.theme?.document?.data?.background_image.url

    const headerDivider = showHeader ? (
      <ShapeDivider
        invert={true}
        color={
          slices[index - 1].primary?.theme?.document?.data?.background_color
        }
      />
    ) : null
    const footerDivider = showFooter ? (
      <ShapeDivider
        position="bottom"
        color={footerColor}
        offset={sliceOffsetDiff * (index + 1)}
        className={dividerBottom}
        // style={{position:"absolute", bottom: 0}}
      />
    ) : null
    // console.log({
    //   showFooter,
    //   nextHasImage,
    //   nextBg:
    //     slices[index + 1]?.primary?.theme?.document?.data?.background_image,
    // })
    if (SliceComponent) {
      return (
        <SliceComponent
          // data-slice-type={slice.slice_type}
          slice={slice}
          sliceType={slice.slice_type}
          padded={padded}
          key={`slice-${index}`}
          textColor={slice?.primary?.theme?.document?.data?.text_color}
          backgroundColor={
            slice?.primary?.theme?.document?.data?.background_color
          }
          backgroundImage={
            slice?.primary?.theme?.document?.data?.background_image
          }
          secondaryImage={
            slice?.primary?.theme?.document?.data?.secondary_image
          }
          // nextColor={nextColor}
          // style={sliceStyle}
          headerDivider={headerDivider}
          footerDivider={footerDivider}
        />
      )
    }
    console.warn("Unknown slice", { slice })
    return null
  })

  return <main className="container">{sliceZoneContent}</main>
}
export default SliceZone
