import * as React from "react"
import { Link, graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Button, Col, Row, Typography } from "antd"
import { SliceSection } from "./slice-section"
import { SliceParameters } from "./default"
import { videoContainer } from "../video.module.less"
import { htmlSerializer } from "../../utils/html-serializer"
const { Title } = Typography

export const VideoHighlight: React.FC<SliceParameters> = ({
  slice,
  headerDivider,
  footerDivider,
  backgroundColor,
  backgroundImage,
  textColor,
  nextColor,
  videoWidth = 400,
  padded,
}) => {
  return (
    <SliceSection
      headerDivider={headerDivider}
      footerDivider={footerDivider}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      textColor={textColor}
      nextColor={nextColor}
      padded={padded}
    >
      {slice?.primary?.title?.richText && (
        <Title level={2}>{RichText.asText(slice.primary.title.richText)}</Title>
      )}
      <Row gutter={[24, 24]}>
        {slice?.items &&
          slice.items.map(({ video }, index) => {
            const {
              title,
              description,
              video_url: { embed_url, html },
            } = video?.document?.data || {}
            // Replace watch?v= with embed/
            const url = embed_url?.replace("watch?v=", "embed/")
            const cleanHtml = html
              // Replace width on html to 400
              .replace(/width="\d+"/, `width="${videoWidth}"`)
              // Remove height on html
              .replace(/height="\d+"/, "")
              .replace(/src=/, "loading='lazy' src=")

            // console.log({ video, title, url })

            return (
              <Col key={index} xs={24} sm={12} md={12} lg={8} xl={8}>
                <div
                  dangerouslySetInnerHTML={{ __html: cleanHtml }}
                  className={videoContainer}
                ></div>
                <RichText
                  render={description.richText}
                  htmlSerializer={htmlSerializer}
                />
              </Col>
            )
          })}
        {slice.primary?.link_url?.url && (
          <Col span={24} style={{ textAlign: "center" }}>
            <Button href={slice.primary.link_url.url}>
              {slice.primary?.link_label || "More"}
            </Button>
          </Col>
        )}
      </Row>
    </SliceSection>
  )
}

export const query = graphql`
  fragment EmbeddedVideoData on PrismicEmbeddedVideo {
    data {
      title
      description {
        richText
      }
      video_url {
        embed_url
        title
        thumbnail_url
        html
      }
    }
  }

  fragment PageDataBodyVideoHighlight on PrismicPageDataBodyVideos {
    items {
      video {
        document {
          ... on PrismicEmbeddedVideo {
            ...EmbeddedVideoData
          }
        }
      }
    }
  }
  fragment HomepageDataBodyVideoHighlights on PrismicHomepageDataBodyVideoHighlights {
    primary {
      title {
        richText
      }
      link_label
      link_url {
        url
      }
      theme {
        document {
          ... on PrismicSectionTheme {
            ...SectionTheme
          }
        }
      }
    }
    items {
      video {
        document {
          ... on PrismicEmbeddedVideo {
            ...EmbeddedVideoData
          }
        }
      }
    }
  }
`
