// Text.js file

import * as React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import { CustomLink } from "../../utils/custom-link"
// import { SliceContent } from "./slice-content"
import { container, oneCol, twoCol } from "./slice.module.less"
import { htmlSerializer } from "../../utils/html-serializer"
import { SliceSection } from "./slice-section"
import { SliceParameters } from "./default"
import { Typography } from "antd"

export const Text: React.FC<SliceParameters> = ({
  slice,
  headerDivider,
  footerDivider,
  backgroundColor,
  backgroundImage,
  secondaryImage,
  nextColor,
}) => {
  const columnClass = slice.primary.columns === "2 Columns" ? twoCol : oneCol

  return (
    <SliceSection
      headerDivider={headerDivider}
      footerDivider={footerDivider}
      className={columnClass}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      secondaryImage={secondaryImage}
      nextColor={nextColor}
    >
      {/* <SliceContent> */}
      <Typography>
        <RichText
          render={slice.primary.content.richText}
          // serializeHyperlink={CustomLink}
          htmlSerializer={htmlSerializer}
        />
      </Typography>
      {/* </SliceContent> */}
    </SliceSection>
  )
}

export const query = graphql`
  fragment PageDataBodyText on PrismicPageDataBodyText {
    primary {
      columns
      content {
        richText
      }
    }
  }
  fragment HomepageDataBodyText on PrismicHomepageDataBodyText {
    primary {
      columns
      content {
        richText
      }
      theme {
        document {
          ... on PrismicSectionTheme {
            ...SectionTheme
          }
        }
      }
    }
  }
`
