import * as React from "react"
import { Link, graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Typography } from "antd"
import { SliceSection } from "./slice-section"
// import { SliceContent } from "./slice-content"
import { SliceParameters } from "./default"
import { container } from "./slice.module.less"
const { Title } = Typography

export const ImageGallery: React.FC<SliceParameters> = ({
  slice,
  headerDivider,
  footerDivider,
  backgroundColor,
  backgroundImage,
  textColor,
  nextColor,
}) => {
  return (
    <SliceSection
      headerDivider={headerDivider}
      footerDivider={footerDivider}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      textColor={textColor}
      nextColor={nextColor}
    >
      {/* <SliceContent> */}
        {/* <RichText render={slice.primary.gallery_title.richText} /> */}
        <Title level={2}>
          {RichText.asText(slice.primary.gallery_title.richText)}
        </Title>
        <div className="gallery">
          {slice.items.map((galleryItem, index) => (
            <div className="gallery-item" key={`gallery-item=${index}`}>
              {galleryItem.image.url && (
                <img src={galleryItem.image.url} alt={galleryItem.image.alt} />
              )}
              <RichText render={galleryItem.image_description.richText} />
              {galleryItem.link.url && (
                <p className="gallery-link">
                  <Link to={galleryItem.link.url}>
                    {RichText.asText(galleryItem.link_label.richText)}
                  </Link>
                </p>
              )}
            </div>
          ))}
        </div>
      {/* </SliceContent> */}
    </SliceSection>
  )
}

export const query = graphql`
  fragment PageDataBodyImageGallery on PrismicPageDataBodyImageGallery {
    primary {
      gallery_title {
        richText
      }
    }
    items {
      image {
        url
        alt
      }
      image_description {
        richText
      }
      link {
        url
        type
        uid
      }
      link_label
    }
  }
  fragment HomepageDataBodyImageGallery on PrismicHomepageDataBodyImageGallery {
    primary {
      gallery_title {
        richText
      }
      theme {
        document {
          ... on PrismicSectionTheme {
            ...SectionTheme
          }
        }
      }
    }
    items {
      image {
        url
        alt
      }
      image_description {
        richText
      }
      link {
        url
        type
        uid
      }
      link_label
    }
  }
`
