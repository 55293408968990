import * as React from "react"
import { graphql, Link, navigate } from "gatsby"
import { RichText } from "prismic-reactjs"
import {
  Card,
  Carousel,
  Typography,
  Row,
  Col,
  Button,
  CarouselProps,
} from "antd"

import { JsonDebugger } from "../json-debugger"
import { SliceSection } from "./slice-section"
import { SliceParameters } from "./default"
import { htmlSerializer } from "../../utils/html-serializer"
import { GatsbyImage } from "gatsby-plugin-image"
const { Title } = Typography

export const TestimonialCarousel: React.FC<SliceParameters> = ({
  slice,
  headerDivider,
  footerDivider,
  backgroundColor,
  backgroundImage,
  nextColor,
  padded,
}) => {
  const { items } = slice
  const carousel = React.useRef(null)
  const slickCarouselSettings: CarouselProps = {
    // accessibility: false,
    // arrows: true,
    // dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,

    // centerMode: true,
    // variableWidth: true,
    // vertical: false,
    // verticalSwiping: true,
    // dotPosition: "right",
    // nextArrow: i,
    // prevArrow: a,
    // responsive: [
    //   {
    //     breakpoint: 991,
    //     settings: {
    //       vertical: false,
    //       verticalSwiping: false,
    //     },
    //   },
    // ],
  }
  return (
    <SliceSection
      sliceType={slice.slice_type}
      headerDivider={headerDivider}
      footerDivider={footerDivider}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      nextColor={nextColor}
      padded={padded}
    >
      <Title level={2}>{RichText.asText(slice.primary.title.richText)}</Title>
      <RichText
        render={slice.primary.description.richText}
        htmlSerializer={htmlSerializer}
      />
      <Row gutter={[6, 24]} align="middle" justify="space-between">
        <Col xs={0} sm={1}>
          <Button
            onClick={() => {
              carousel.current.prev()
            }}
            aria-hidden="true"
          >
            ←
          </Button>
        </Col>
        <Col span={24} sm={18}>
          <Carousel
            {...slickCarouselSettings}
            ref={(ref) => (carousel.current = ref)}
          >
            {items &&
              items.map((item, i) => {
                const image = (
                  <GatsbyImage
                    alt={
                      item.testimonial.document.data.images[0].image.alt ||
                      RichText.asText(slice.primary.title.richText)
                    }
                    objectFit={"fullWidth"}
                    image={
                      item.testimonial.document.data.images[0].image
                        .gatsbyImageData
                    }
                  />
                )
                return (
                  <Link key={i} to={item.testimonial.document.url}>
                    <Card>
                      <Row gutter={24} wrap={true}>
                        <Col span={24} md={12}>
                          {image}
                        </Col>
                        <Col span={24} md={12}>
                          <Title level={4}>
                            {item.testimonial.document.data.title}
                          </Title>
                          <RichText
                            render={
                              item.testimonial.document.data.message.richText
                            }
                            htmlSerializer={htmlSerializer}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Link>
                )
              })}
          </Carousel>
        </Col>
        <Col xs={0} sm={1}>
          <Button
            onClick={() => {
              carousel.current.next()
            }}
            aria-hidden="true"
          >
            →
          </Button>
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <Button
            href="/testimonials/"
            onClick={(e) => {
              e.preventDefault()
              return navigate("/testimonials/")
            }}
          >
            More Testimonials
          </Button>
        </Col>
      </Row>
      {/* <JsonDebugger json={items} /> */}
    </SliceSection>
  )
}

export const query = graphql`
  fragment PageDataBodyTestimonialCarousel on PrismicPageDataBodyTestimonialCarousel {
    primary {
      columns
      content {
        richText
      }
    }
  }
  fragment HomepageDataBodyTestimonialCarousel on PrismicHomepageDataBodyTestimonialCarousel {
    primary {
      title {
        richText
      }
      link_label

      eyebrow_headline {
        richText
      }
      description {
        richText
      }

      theme {
        document {
          ... on PrismicSectionTheme {
            ...SectionTheme
          }
        }
      }
    }
    items {
      testimonial {
        document {
          ... on PrismicTestimonial {
            id
            url
            data {
              products {
                product {
                  url
                  uid
                }
              }
              title
              message {
                richText
              }
              images {
                image {
                  alt
                  gatsbyImageData(
                    width: 720
                    height: 504
                    imgixParams: { fit: "crop", crop: "entropy,faces" }
                  )
                  #fixed(height: 300, width: 400) {
                  #  ...GatsbyImgixFixed
                  #}
                }
              }
            }
          }
        }
      }
    }
  }
`
