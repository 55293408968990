import * as React from "react"
import { Link, graphql } from "gatsby"
import { Col, Row, Typography } from "antd"
import { RichText } from "prismic-reactjs"
import { SliceSection } from "./slice-section"
// import { SliceContent } from "./slice-content"
import { container, section } from "./slice.module.less"
import { SliceParameters } from "./default"
import { GatsbyImage } from "gatsby-plugin-image"
import { htmlSerializer } from "../../utils/html-serializer"

const { Title } = Typography

export const ImageHighlight: React.FC<SliceParameters> = ({
  slice,
  headerDivider,
  footerDivider,
  backgroundColor,
  backgroundImage,
  secondaryImage,
  nextColor,
  padded,
}) => (
  <SliceSection
    headerDivider={headerDivider}
    footerDivider={footerDivider}
    backgroundColor={backgroundColor}
    backgroundImage={backgroundImage}
    secondaryImage={secondaryImage}
    nextColor={nextColor}
    padded={padded}
  >
    {/* <SliceContent> */}
      <Row gutter={[24, 24]}>
        <Col span={24} md={13} order={slice.primary?.image_position === 'Left' ? 1 : 0}>
          <Title level={2}>
            {RichText.asText(slice.primary.title.richText)}
          </Title>
          <RichText render={slice.primary.description.richText}  htmlSerializer={htmlSerializer} />
          <RichText render={slice.primary.content.richText}  htmlSerializer={htmlSerializer} />
          {slice.primary.link.url && (
            <Link to={slice.primary.link.url} className="ant-typography">
              {slice.primary.link_label ? slice.primary.link_label : "More"}
            </Link>
          )}
        </Col>
        {slice?.primary?.featured_image?.gatsbyImageData && (
          <Col span={24} md={11}>
            <GatsbyImage
              image={slice.primary.featured_image.gatsbyImageData}
              alt={slice.primary.featured_image.alt || ""}
              // imgStyle={{ ...style }}
            />
          </Col>
        )}
      </Row>
    {/* </SliceContent> */}
  </SliceSection>
)

export const query = graphql`
  fragment PageDataBodyImageHighlight on PrismicPageDataBodyImageHighlight {
    primary {
      featured_image {
        url
        alt
      }
      title {
        richText
      }
      description {
        richText
      }
      #content {
      #  richText
      #}
      link {
        url
        type
        uid
      }
      link_label
      # {
      #   richText
      # }
    }
  }
  fragment HomepageDataBodyImageHighlight on PrismicHomepageDataBodyImageHighlight {
    primary {
      featured_image {
        # url
        alt
        gatsbyImageData
      }
      title {
        richText
      }
      description {
        richText
      }
      content {
        richText
      }
      image_position
      link {
        url
        type
        uid
      }
      link_label
      # link_label {
      #   richText
      # }
      theme {
        document {
          ... on PrismicSectionTheme {
            ...SectionTheme
          }
        }
      }
    }
  }
`
