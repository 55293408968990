import * as React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

export const HtmlSlice = ({ slice }) => (
  <section
    className="content-section slice"
    dangerouslySetInnerHTML={{ __html: slice?.primary?.code?.text || "" }}
  >
  </section>
)

export const query = graphql`
  fragment PageDataBodyHtml on PrismicPageDataBodyHtmlCode {
    primary {
      code {
        text
      }
    }
  }
`
