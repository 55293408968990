import * as React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Typography } from "antd"
import { CustomLink } from "../../utils/custom-link"
import { SliceSection } from "./slice-section"
// import { SliceContent } from "./slice-content"
import { ProductListing } from "../product-listing"
import { JsonDebugger } from "../json-debugger"

import { SliceParameters } from "./default"
import { featuredProducts } from "./slice.module.less"

const { Title, Paragraph } = Typography

export const FeaturedProducts: React.FC<SliceParameters> = ({
  slice,
  headerDivider,
  footerDivider,
  backgroundColor,
  backgroundImage,
  nextColor,
  padded,
}) => {
  const products = slice.items.map((slice) => slice.product.document)
  return (
    <SliceSection
      headerDivider={headerDivider}
      footerDivider={footerDivider}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      nextColor={nextColor}
      className={featuredProducts}
      padded={padded}
    >
      {/* <SliceContent> */}
      <Title level={2}>{RichText.asText(slice.primary.title.richText)}</Title>
      <Paragraph>
        <RichText
          render={slice.primary.description.richText}
          serializeHyperlink={CustomLink}
        />
      </Paragraph>
      <ProductListing products={products} poppedProduct={true} />
      {/* <JsonDebugger json={{ products }} /> */}
      {/* </SliceContent> */}
    </SliceSection>
  )
}

export const query = graphql`
  fragment PageDataBodyFeaturedProducts on PrismicPageDataBodyFeaturedProducts {
    items {
      product {
        document {
          ... on PrismicProduct {
            ...ProductCard
          }
        }
      }
    }
    primary {
      title {
        richText
      }
      description {
        richText
      }
    }
  }
  fragment HomepageDataBodyFeaturedProducts on PrismicHomepageDataBodyFeaturedProducts {
    items {
      product {
        document {
          ... on PrismicProduct {
            ...ProductCard
          }
        }
      }
    }
    primary {
      title {
        richText
      }
      description {
        richText
      }
      theme {
        document {
          ... on PrismicSectionTheme {
            ...SectionTheme
          }
        }
      }
    }
  }
`
