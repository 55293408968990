import * as React from "react"
import { JsonDebugger } from "../json-debugger"
import { SliceParameters } from "./default"
import { SliceContent } from "./slice-content"
import {
  section,
  container,
  containerPadded,
  lightText,
  darkText,
  secondaryImageWrapper,
} from "./slice.module.less"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
interface SliceSectionParams extends SliceParameters {
  className?: string
  textColor?: string
  sliceType?: string
  padded?: boolean
}
export const SliceSection: React.FC<Omit<SliceSectionParams, "slice">> = ({
  children,
  headerDivider,
  footerDivider,
  backgroundColor,
  backgroundImage,
  secondaryImage,
  nextColor,
  className,
  sliceType = null,
  textColor = "Dark",
  style = {},
  padded = false,
}) => {
  const sectionStyles: [string, unknown][] = []
  const overlayStyles: [string, unknown][] = []
  const textClass = textColor === "Dark" ? darkText : lightText
  if (backgroundColor) {
    sectionStyles.push(["backgroundColor", `${backgroundColor}`])
    overlayStyles.push(
      [
        "backgroundImage",
        // `linear-gradient(${backgroundColor}, rgba(${backgroundColor}, 0))`,
        !nextColor
          ? `linear-gradient(${backgroundColor}, ${backgroundColor}00 70%)`
          : `linear-gradient(${backgroundColor}, ${backgroundColor}00 70%, ${nextColor}44)`,
      ]
      // ["color", "#f00"]
    )
  } else if (nextColor) {
    overlayStyles.push(
      ["backgroundImage", `linear-gradient(${nextColor}00 70%, ${nextColor})44`]
      // ["color", "#f00"]
    )
  } else {
  }
  if (backgroundImage?.url) {
    sectionStyles.push(["backgroundImage", `url(${backgroundImage.url})`])
  }

  const sectionStyle = Object.fromEntries(sectionStyles)
  const overlayStyle = Object.fromEntries(overlayStyles)
  // const overlayStyle = {
  //   backgroundImage: `linearGradient(${backgroundColor},#f00)`,
  // }
  // if (secondaryImage) {
  // const secondary = getImage(secondaryImage.fixed)
  // console.log({ secondaryImage, secondary })
  // }
  return (
    <section
      className={[section, className, textClass].join(" ").trim()}
      style={sectionStyle}
      data-slice-type={sliceType}
    >
      <div style={overlayStyle}>
        {headerDivider}
        <div className={padded ? containerPadded : container} style={style}>
          <SliceContent>{children}</SliceContent>
          {secondaryImage?.gatsbyImageData && (
            <div className={secondaryImageWrapper}>
              <GatsbyImage
                // objectFit={"contain"}
                // layout="fixed"
                // className={secondaryImageWrapper}
                image={secondaryImage.gatsbyImageData}
                // className={productCardImage}
                alt={secondaryImage.alt || ""}
                // imgStyle={{ ...style }}
                // style={{position: "absolute", bottom: 0}}
              />
            </div>
          )}
        </div>
        {footerDivider}
      </div>
    </section>
  )
}
