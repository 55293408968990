// Text.js file

import * as React from "react"
import { graphql, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Typography } from "antd"

// import { CustomLink } from "../../utils/custom-link"
import { SliceSection } from "./slice-section"
// import { SliceContent } from "./slice-content"
import {
  heroSubText,
  shadow,
  center,
  hero,
  heroTitle,
} from "./slice.module.less"
import { htmlSerializer } from "../../utils/html-serializer"
import { SliceParameters } from "./default"

const { Title, Paragraph } = Typography

export const HeroSlice: React.FC<SliceParameters> = ({
  slice,
  headerDivider,
  footerDivider,
  backgroundColor,
  backgroundImage,
  secondaryImage,
  textColor,
  nextColor,
  padded,
}) => {
  // const columnClass = slice.primary.columns === "2 Columns" ? twoCol : oneCol
  const styles = slice.primary?.theme?.document?.data?.min_height
    ? { minHeight: `${slice.primary.theme.document.data.min_height}px` }
    : {}

  return (
    <SliceSection
      headerDivider={headerDivider}
      footerDivider={footerDivider}
      className={`${center} ${hero}`}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      secondaryImage={secondaryImage}
      nextColor={nextColor}
      textColor={textColor}
      style={styles}
      padded={padded}
    >
      {/* <SliceContent> */}
      {/* <Title className={heroTypography}>{title}</Title> */}
      <Title level={2} className={[heroTitle, shadow].join(" ")}>
        {RichText.asText(slice.primary.title.richText)}
      </Title>
      {/* <Paragraph className={heroTypography}>{description}</Paragraph> */}
      {/* <Paragraph>{description}</Paragraph> */}
      <div className={[shadow, heroSubText].join(" ")}>
        <RichText
          render={slice.primary.content.richText}
          htmlSerializer={htmlSerializer}
        />
      </div>
      {slice.primary.link_url.url && (
        <>
          {/* <Button>{linkLabel}</Button> */}
          {/* <p> */}
          <Link className="ant-btn" to={slice.primary.link_url.url}>
            <span>{slice.primary.link_label}</span>
          </Link>
          {/* </p> */}
        </>
      )}
      {/* <RichText
          render={slice.primary.content.richText}
          htmlSerializer={htmlSerializer}
        /> */}
      {/* </SliceContent> */}
    </SliceSection>
  )
}

export const query = graphql`
  fragment PageDataBodyHero on PrismicPageDataBodyHero {
    primary {
      title {
        richText
      }
      content {
        richText
      }
      link_label
      link_url {
        url
      }
      theme {
        document {
          ... on PrismicSectionTheme {
            ...SectionTheme
          }
        }
      }
    }
  }
  fragment HomepageDataBodyHero on PrismicHomepageDataBodyHero {
    primary {
      title {
        richText
      }
      content {
        richText
      }
      link_label
      link_url {
        url
      }
      theme {
        document {
          ... on PrismicSectionTheme {
            ...SectionTheme
          }
        }
      }
    }
  }
`
