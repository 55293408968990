// Text.js file

import * as React from "react"
import { graphql, Link, navigate } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Button, Card, Col, Row, Typography } from "antd"

import { CustomLink } from "../../utils/custom-link"
import { SliceSection } from "./slice-section"
// import { SliceContent } from "./slice-content"
import { container, cardCarousel, cardCarouselCard } from "./slice.module.less"
import { JsonDebugger } from "../json-debugger"
import { SliceParameters } from "./default"
import { linkResolver } from "../../utils/link-resolver"
import { htmlSerializer } from "../../utils/html-serializer"
import Img from "gatsby-image"

const { Title } = Typography
export const CardsCarousel: React.FC<SliceParameters> = ({
  slice,
  headerDivider,
  footerDivider,
  backgroundColor,
  backgroundImage,
  textColor,
  nextColor,
  padded
}) => {
  const items: any[] = slice.card_items
  const defaultSpan = items.length >= 3 ? 8 : items.length >= 2 ? 12 : 24
  return (
    <SliceSection
      headerDivider={headerDivider}
      footerDivider={footerDivider}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      nextColor={nextColor}
      textColor={textColor}
      className={cardCarousel}
      padded={padded}
    >
      {/* <SliceContent> */}
      <Title level={2}>{RichText.asText(slice.primary.title.richText)}</Title>
      <RichText
        render={slice.primary.description.richText}
        htmlSerializer={htmlSerializer}
      />
      <Row gutter={[24, 24]} justify={"space-around"}>
        {items &&
          items.map((item, i) => {
            const actions = []

            if (item.link_label) {
              const onClick = (
                e: React.MouseEvent<
                  HTMLButtonElement | HTMLAnchorElement,
                  MouseEvent
                >
              ) => {
                e.preventDefault()
                navigate(item.link.url)
              }
              actions.push(
                <Button href={item.link.url} type="primary" onClick={onClick}>
                  {item.link_label}
                </Button>
              )
            }
            const CoverImage = item.image.fluid && (
              <Img
                alt={
                  item.image.alt ||
                  `Supporting image form ${RichText.asText(
                    item.title.richText
                  )}`
                }
                fluid={item.image.fluid}
              />
            )
            return (
              <Col key={i} span={24} md={defaultSpan}>
                <Card
                  actions={actions}
                  cover={CoverImage}
                  className={cardCarouselCard}
                >
                  <Card.Meta
                    title={RichText.asText(item.title.richText)}
                    description={
                      <RichText
                        render={item.content.richText}
                        htmlSerializer={htmlSerializer}
                      />
                    }
                  />
                  {/* <JsonDebugger json={item} /> */}
                </Card>
              </Col>
            )
          })}
      </Row>
      {/* </SliceContent> */}
    </SliceSection>
  )
}

export const query = graphql`
  fragment PageDataBodyCardsCarousel on PrismicPageDataBodyCardsCarousel {
    card_items: items {
      additional_info {
        richText
      }
      content {
        richText
      }
      image {
        url
        alt
        fluid(maxWidth: 400) {
          ...GatsbyImgixFluid
        }
      }
      link {
        url
      }
      link_label
      title {
        richText
      }
    }
    primary {
      title {
        richText
      }
      eyebrow_headline {
        richText
      }
      description {
        richText
      }
    }
    id
  }
  fragment HomepageDataBodyCardsCarousel on PrismicHomepageDataBodyCardsCarousel {
    card_items: items {
      additional_info {
        richText
      }
      content {
        richText
      }
      image {
        url
        alt
        fluid(maxWidth: 400) {
          ...GatsbyImgixFluid
        }
      }
      link {
        url
      }
      link_label
      title {
        richText
      }
    }
    primary {
      title {
        richText
      }
      eyebrow_headline {
        richText
      }
      description {
        richText
      }
      theme {
        document {
          ... on PrismicSectionTheme {
            ...SectionTheme
          }
        }
      }
    }
    id
  }
`
